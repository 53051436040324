@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --container-height-full: 100%;
  --container-width-full: 100%;
  --full-viewport-height: calc(var(--vh) * 100);
  --full-width: 100%;
  --width-input-large: 17rem;
  --width-input-giveaway-large: 20rem;
  --width-btn-large: 20rem;
  --width-btn-small: 6.5rem;

  --popup-box-width: 25rem;

  --display-container: block;
  --visibility-responsive: none;
  --visibility-desktop: visible;

  --color-primary: #d3f6fd;
  --color-primary-light: #84d2ce26;
  --color-primary-light-background: #edf8f8;
  --color-primary-dark: #3b8d9e;
  --color-header-light: #fff;
  --color-accent: #7d3ee1;
  --color-accent-20: rgb(125, 62, 225, 0.2);
  --color-accent-10: rgb(125, 62, 225, 0.1);
  --color-accent-influencer: #7d3ee1;
  --color-accent-manager: #053cff;

  --color-input-background: #fff;
  --color-input-disabled: #e0e0e0;
  --color-input-disabled-text: #545454;

  --color-button-primary: #1f1f1f;
  --color-button-secondary: #fff;
  --color-button-tertiary: #f6f6f6;
  --color-button-hover: #ff0570;
  --color-button-disabled: #a7a7a7;

  --color-primary-black: #1f1f1f;
  --color-primary-black-80: rgba(31, 31, 31, 0.8);
  --color-primary-black-60: rgba(31, 31, 31, 0.6);
  --color-primary-black-40: rgba(31, 31, 31, 0.4);
  --color-primary-black-20: rgba(31, 31, 31, 0.2);
  --color-primary-black-10: rgba(31, 31, 31, 0.1);
  --color-primary-black-05: rgba(31, 31, 31, 0.05);
  --color-primary-black-03: rgba(31, 31, 31, 0.03);

  --font-color-tip-green: #0d9152;
  --backgound-color-tip-green: #e7f5e8;

  --color-success: #33b065;
  --color-danger: #ff0000;

  --color-danger-box-title: #ffcaca;
  --color-danger-box-content: #ff9d9d;
  --color-danger-text: #ed4141;
  --color-suggestion-box-title: #caffec;
  --color-suggestion-box-content: #9dffdc;
  --color-suggestion-text: #22856d;

  --color-facebook-blue: #1877f2;
  --color-google-blue: #4285f4;

  --font-size-small: 0.8rem;
  --font-size-heading: 1.5rem;
  --font-size-normal: 1.2rem;
  --font-size-input-text: 1rem;
  --font-size-input-area: 1rem;

  --font-color-primary: #3b8d9e;
  --font-color-primary50: #9cc5ce;
  --font-color-black: rgba(0, 0, 0, 0.8);

  --color-red-solid: #e84237;
  --color-red-10-solid: #fdeceb;
  --color-red-20-solid: #fad9d7;

  --font-size-btn-sidemenu: 0.95rem;
  --font-size-header-medium: 1.1rem;
  --font-size-header-large: 1.2rem;

  --grid-dashboard-layout-areas: "sidenav nav" "sidenav body_content";
  --grid-dashboard-layout-columns: 70px auto;
  --grid-dashboard-layout-rows: 55px calc(var(--full-viewport-height) - 55px);

  --grid-dashboard-areas: "sidebar_header page_content"
    "sidebar_menu page_content";
  --grid-dashboard-columns: minmax(235px, min-content) auto;
  --grid-dashboard-rows: 135px calc(var(--full-viewport-height) - 135px);

  --grid-empty-dashboard-areas: "heading" "role_selection" "role_content"
    "action_button" "superpowers";
  --grid-empty-dashboard-columns: 100%;
  --grid-empty-dashboard-rows: 135px 2fr 2fr 2fr 4fr;

  --grid-registration-layout-areas: "form infographic";
  --grid-registration-layout-columns: 43% 1fr;

  --grid-giveaway-create-areas: "header" "form" "action_items";
  --grid-giveaway-create-rows: minmax(90px, auto) minmax(50%, 70%) 15%;
  --grid-giveaway-create-columns: 100%;
  --grid-giveaway-create-container-background: #fff;
  --giveaway-create-container-width: 40rem;
  --grid-giveaway-create-background: #d4e3e3;

  --font-size-H1: 24px;
  --font-size-H2: 20px;
  --font-size-H3: 18px;
  --font-size-H4: 16px;

  --font-size-SH1: 16px;
  --font-size-SH2: 14px;
  --font-size-SH3: 12px;

  --font-size-Text1: 14px;
  --font-size-Text2: 12px;
  --font-size-Text3: 10px;

  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --font-weight-light: 300;

  --font-underline: underline;

  --gradient-bg: linear-gradient(250deg, #7252f0, #8729d1);
}

html,
body,
div,
form,
button,
input,
textarea,
.App {
  font-family: "Poppins", sans-serif;
  scrollbar-width: none;
  /*   animation: fade-in ease-in 0.2s; */
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

h2,
h3,
h4,
h5,
p,
form,
ul {
  margin: unset;
  padding: unset;
}

div,
input,
textarea,
button,
form {
  box-sizing: border-box;
}

img {
  width: inherit;
  height: inherit;
  object-fit: contain;
  border-radius: inherit;
}

ul {
  list-style: none;
}

details:focus-visible,
summary:focus-visible,
button:focus-visible {
  outline: none !important;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 600;
}

.fw-800 {
  font-weight: 600;
}

.fs-0-75 {
  font-size: 0.75rem !important;
}

.fs-0-9 {
  font-size: 0.9rem !important;
}

.fs-1 {
  font-size: 1rem;
}

.fs-1-1 {
  font-size: 1.1rem !important;
}

.fs-1-2 {
  font-size: 1.2rem !important;
}

.H1 {
  font-size: 24px;
}

.H2 {
  font-size: 20px;
}
.H3 {
  font-size: 18px;
}
.H4 {
  font-size: 16px;
}

.SH1 {
  font-size: 16px;
}

.SH2 {
  font-size: 14px;
}
.SH3 {
  font-size: 12px;
}

.Text1 {
  font-size: 14px;
}

.Text2 {
  font-size: 12px;
}
.Text3 {
  font-size: 10px;
}

.text-capitalize {
  text-transform: capitalize;
}

.lil-padding {
  padding: 0.5rem;
}

.img-100 {
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.flex-gap-10 {
  gap: 10px;
}

.Container {
  width: 100%;
  height: 100%;
}

.text-bold {
  font-weight: 600 !important;
}

.width-100 {
  width: 100%;
}

.full-screen {
  width: 100vw;
  height: 100vh;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.box-align-center-top {
  margin: auto 0;
}

.box-align-center-left {
  margin: 0 auto;
}

.top-0-fixed {
  position: fixed;
  top: 0;
  width: 100%;
}

.box-align-center {
  margin: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column > div {
  width: 100%;
}

.flex-row {
  display: flex;
}

.font-content-medium {
  font-size: 1.1rem !important;
}

.font-content-small {
  font-size: 0.8rem !important;
}

.flex-justify-center {
  justify-content: center !important;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-align-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-6 {
  gap: 6px;
}

.font-color-primary50 {
  color: var(--font-color-primary50);
}

.scrollx {
  overflow-x: scroll;
}

.no-styles {
  background: none !important;
  border: none !important;
  max-height: min-content !important;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  margin: 1rem 0;
  padding: 0 0;
}

.height-small {
  min-height: 15rem !important;
  max-height: 15rem !important;
}

.margin-top-small {
  margin-top: 0.5rem;
}

.margin-bottom-small {
  margin-bottom: 0.5rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-left-small {
  margin-left: 0.5rem;
}

.margin-left-medium {
  margin-left: 0.8rem;
}

.mt-4 {
  margin-top: 4px;
}

.mt-12 {
  margin-top: 12px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.text-primary {
  font-size: var(--font-size-normal);
  font-weight: 400;
}

.text-secondary {
  font-size: 0.9rem;
  color: gray;
}
.square-image-small {
  width: 100px;
  height: 100px;
}
.span-text-bold {
  font-weight: 600;
}

.span-text {
  color: var(--color-accent);
}

.span-align-right {
  float: right;
}

.span-icon-15 {
  height: 15px;
  width: 15px;
}

.span-icon-20 {
  height: 20px;
  width: 20px;
}

.span-icon-30 {
  max-height: 30px;
  max-width: 30px;
}

.span-icon-35 {
  height: 35px;
  width: 35px;
}

.border-radius-0-5 {
  border-radius: 0.5rem;
}

.bkg-circle-grey {
  background-color: #c4c4c4;
  border-radius: 12rem;
  padding: 0.2rem 0.4rem;
}

.border-top-pb-10 {
  border-top: solid 1px var(--color-primary-black-10);
}

.border-bottom-grey {
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
}

.border-bottom-lgreen {
  border-bottom: 1.4px solid var(--color-primary-black-10);
}

.accent-text {
  color: var(--color-accent);
  font-weight: inherit;
}

.accent-text-main {
  color: var(--color-accent-influencer);
  font-weight: 600;
  text-align: center;
}

button {
  border: none;
  font-weight: 500;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s;
  background: none;
  font-family: "Poppins", sans-serif;
  padding: 0.4rem 0.8rem;
}

button > img {
  object-fit: contain;
}
.btn-disabled {
  background-color: var(--color-button-disabled) !important;
}

.btn-primary-icon-round,
.btn-accent-icon-round {
  border-radius: 100% !important;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary-icon-round > span,
.btn-accent-icon-round > span {
  height: 24px;
  width: 24px;
}

.btn-with-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-with-icon > span {
  margin-left: 10px;
  height: 24px;
  width: 24px;
}

.span-icon-round {
  height: 24px;
  width: 24px;
}

.btn-primary,
.btn-primary-icon-round {
  color: rgba(255, 255, 255, 1);
  background-color: var(--color-button-primary);
}

.btn-accent-icon-round {
  background: var(--gradient-bg);
}

.btn-secondary {
  background-color: var(--color-button-secondary);
  /*  color: var(--color-primary-dark); */
  color: #1f1f1f;
}

.btn-secondary:hover {
  background-color: var(--color-primary-light-background);
}

.btn-tertiary {
  background-color: var(--color-button-tertiary);
  /* color: var(--color-primary-dark); */
  color: #1f1f1f;
}

.btn-accent {
  /* background-color: var(--color-accent-influencer); */
  background: var(--gradient-bg);
  color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
}

.btn-large {
  text-align: center;
  min-width: 12rem;
  height: 2.5rem;
  font-size: 1rem;
}

.btn-small {
  height: 2.3rem;
  font-size: 1rem;
  min-width: 6rem;
}

.icon-btn-small {
  width: 1rem;
  height: 1rem;
}

.icon-btn {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 100%;
}

.Icon-btn-24 {
  padding: 8px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.Icon-18 {
  width: 18px;
  height: 18px;
}

/*.svg-hover-fill:hover {
  stroke: rgba(255, 255, 255, 0.8);
}*/
.Sidebar-menu-light > .text-btn-icon {
  font-size: var(--font-size-btn-sidemenu);
  font-weight: 600;
  color: var(--font-color-black);
  width: 12rem;
  text-align: left;
  height: 3rem;
  margin: 0.5rem 1.2rem;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-btn-icon > img {
  width: 1.5rem;
  height: 1.5rem;
}

.filter-gray {
  filter: grayscale(100%);
}

.btn-tag {
  width: 1rem;
  height: 1rem;
  padding: 0.3rem;
  border-radius: 5rem;
  font-size: var(--font-size-small);
  float: right;
  text-align: center;
}

.tag-red {
  background-color: #ed4141;
  color: #fff;
}
.tag-yellow {
  background-color: #f6a406;
  color: #fff;
}

.tag-green {
  background-color: #66b660;
  color: #fff;
}

.tag-grey {
  background-color: #ecf4f6;
}

.text-btn-icon:hover {
  background-color: #f4f4f4;
}

.btn-icon-round {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: #fff;
  text-align: center;
}

.Btn {
  font-size: var(--font-size-SH2);
  font-weight: 500;
  height: 44px;
}

.Btn-icon-round {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  /* background-color: #fff; */
  background: var(--gradient-bg);
  display: flex;
  align-items: center;
  padding: 10px !important;
}

.icon-btn > img {
  width: 100%;
  transition: all 0.3s;
  border-radius: 100%;
}

.icon-btn > img:hover {
  filter: invert(200%);
  filter: brightness(285%);
}

.btn-secondary-form,
.btn-primary-form {
  font-size: 1.1rem;
  height: 2.5rem;
  min-width: var(--width-btn-small);
  margin: auto 0;
  font-weight: 500;
}

.btn-primary-form {
  background-color: var(--color-button-primary);
  color: #fff;
}

.btn-secondary-form {
  background-color: #fff;
  color: var(--font-color-primary);
}

.btn-green {
  background-color: var(--color-primary-dark);
  color: #fff;
}

.Avatar-img {
  width: 3.5rem;
  text-align: center;
  color: var(--color-primary-dark);
  font-size: var(--font-size-small);
  margin: 0.5rem auto 0.5rem auto;
}

.text-link {
  color: var(--color-primary-black) !important;
  text-decoration: underline;
  font-size: var(--font-size-small);
  display: inline;
  cursor: pointer;
  font-weight: 500;
}

.tab-selected {
  background-color: var(--color-accent) !important;
  color: #fff !important;
}

.inline-span {
  display: inline;
}

.section-heading {
  font-size: var(--font-size-small);
  text-align: left;
  color: var(--color-text-dark);
  width: 100%;
}

.visibility-responsive {
  display: var(--visibility-responsive) !important;
}

.visibility-desktop {
  display: var(--visibility-desktop) !important;
}

.Active-step {
  display: inline;
  position: relative;
  z-index: 1;
}

.blur-shadow {
  position: absolute;
  background-color: rgba(3, 121, 129, 0.8);
  width: 12px;
  height: 12px;
  border-radius: 15rem;
  top: 7px;
  right: 4px;
  bottom: 0;
  filter: blur(6px);
  z-index: -1;
}

.Royal-reward-tag {
  background: url("../img/svg/giveaways/reward_tag_bkg.png");
  background-size: cover;
  border-radius: 13rem;
  padding: 0.3rem 1rem;
  position: absolute;
  top: -1rem;
  margin-left: 0.8rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
}

.button-tag {
  width: 14rem;
  height: 3rem;
  background-color: #fff;
  color: var(--color-accent);
  border-radius: 2rem;
  text-align: center;
  cursor: pointer;
  margin: 0.5rem 0;
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}
.button-tag > p {
  padding: 0.75rem 0;
}

.Form-field-flex {
  display: flex;
  align-items: center;
}

.Form-field-flex > label {
  font-size: 0.9rem;
  text-align: left;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.Form-image-input {
  display: grid;
  grid-template-areas:
    "preview selection"
    "presets presets";
  grid-template-rows: 8rem 4.5rem;
  grid-template-columns: 8rem auto;
  row-gap: 15px;
}

.Form-image-preview {
  grid-area: preview;
  border-radius: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.Form-image-selection {
  grid-area: selection;
  text-align: center;
}

.Form-image-presets {
  grid-area: presets;
  display: flex;
}

.Image-preset-option {
  margin-right: 0.5rem;
  cursor: pointer;
}

.Upload-button {
  font-size: 1.3rem;
  background-color: rgba(0, 0, 0, 0.03);
  color: var(--color-primary-dark);
  padding: 0.5rem 1rem;
  display: flex;
  margin: auto;
}

.Upload-button:hover {
  background-color: #fff;
}

.Upload-button > span {
  height: 2rem;
}

.Inf-avatar > img {
  width: 100%;
  height: 100%;
}

.dotted-divider {
  width: 60%;
  height: 2px;
  border-top: 1px solid var(--color-primary-black-05);
  margin: auto;
}
span > img {
  height: inherit;
  width: inherit;
}

.btn-full {
  width: 100%;
  padding: 0.6rem 0rem;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c6d4d4;
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.1);
}

.padding-1 {
  padding: 0 1rem 1rem;
}

.padding-sides-2-desktop {
  padding: 0 2rem;
}

.fc-pb {
  color: var(--color-primary-black);
}

.fc-pb-80 {
  color: var(--color-primary-black-80);
}

.fc-pb-60 {
  color: var(--color-primary-black-60);
}

.fc-pb-40 {
  color: var(--color-primary-black-40);
}

.fc-pb-20 {
  color: var(--color-primary-black-20);
}

.fc-pb-05 {
  color: var(--color-primary-black-05);
}

.fc-pb-03 {
  color: var(--color-primary-black-03);
}

.fc-accent {
  color: var(--color-accent) !important;
}

.fc-primary-dark {
  color: var(--color-primary-black) !important;
}

.fc-white {
  color: #fff;
}

.fc-youtube {
  color: #ff0100;
}

.fc-facebook {
  color: #2d5fac;
}

.fc-instagram {
  color: #b44082;
}

.fc-twitter {
  color: #50abf1;
}

.fc-custom {
  color: var(--color-accent);
}

input:checked + .slider {
  background-color: var(--color-primary-dark);
}

input:focus + .slider {
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.1);
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

details:active,
summary:active {
  outline: none !important;
  border: none !important;
}

.User-avatar-nav {
  font-weight: 500;
  border-radius: 100%;
  height: 54px !important;
  width: 54px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /*   border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 15%,
      #fff 15%,
      #fff 85%,
      rgba(0, 0, 0, 0) 85%
    )
    0% 0% 100% 0%; */
  margin-bottom: calc(22px + 0.5rem);
}

.User-avatar-nav {
  background-color: #595959;
  border: solid 4px #fff;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 300;
}

.flex-responsive {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-align-center-responsive {
  text-align: left;
}

.Ad-unit-container > div > * {
  display: block !important;
  margin: auto !important;
}

.Ad-unit-container > * {
  display: block !important;
  margin: auto !important;
}

@media only screen and (max-width: 600px) {
  .text-align-center-responsive {
    text-align: center;
  }
  .flex-responsive {
    flex-direction: column;
  }

  :root {
    --grid-dashboard-layout-areas: "sidenav nav" "sidenav body_content";
    --grid-dashboard-layout-columns: 0 100%;
    --grid-dashboard-layout-rows: 55px calc(var(--full-viewport-height) - 55px);

    --grid-dashboard-areas: "sidebar_header" "sidebar_menu" "page_content";
    --grid-dashboard-columns: 100%;
    --grid-dashboard-rows: auto auto 100%;

    --grid-empty-dashboard-areas: "heading" "role_selection" "role_content"
      "action_button" "superpowers";
    --grid-empty-dashboard-columns: 100%;
    --grid-empty-dashboard-rows: 7rem 15rem auto 8rem auto;

    --grid-registration-layout-areas: "form infographic";
    --grid-registration-layout-columns: 100% 0;

    --grid-giveaway-create-container-background: linear-gradient(
      0deg,
      #ff3a4a,
      #ff8c00,
      #40e0d0
    );
    --grid-giveaway-create-background: #edf8f8;
    --giveaway-create-container-width: 100%;
    --grid-giveaway-create-rows: 20% auto 10%;

    --display-container: flex;
    --visibility-responsive: visible;
    --visibility-desktop: none;

    --popup-box-width: 90%;
    --width-input-large: 75%;
    --width-input-giveaway-large: 85%;
    --width-btn-large: 65%;
    --full-width: 100vw;

    --font-size-H1: 20px;
    --font-size-H2: 18px;
    --font-size-H3: 16px;
    --font-size-H4: 16px;

    --font-size-SH1: 14px;
    --font-size-SH2: 12px;
    --font-size-SH3: 12px;

    --font-size-Text1: 12px;
    --font-size-Text2: 10px;
    --font-size-Text3: 10px;
  }

  ::-webkit-scrollbar {
    display: block !important;
    width: 4px !important;
    height: 4px !important;
    background-color: #f0eeee;
  }

  ::-webkit-scrollbar-thumb {
    background: #acacac;
    border-radius: 5rem;
    margin-right: 0.3rem;
  }

  .btn-primary:hover {
    background: var(--color-button-primary) !important;
  }

  .dotted-divider {
    width: 80%;
  }

  .padding-sides-2-desktop {
    padding: 0 1rem;
  }

  .H1 {
    font-size: 20px;
  }

  .H2 {
    font-size: 18px;
  }
  .H3 {
    font-size: 16px;
  }
  .H4 {
    font-size: 14px;
  }

  .SH1 {
    font-size: 14px;
  }

  .SH2 {
    font-size: 12px;
  }

  .Text1 {
    font-size: 12px;
  }

  .Text2 {
    font-size: 10px;
  }
}

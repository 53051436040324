/****************************************
STYLES FOR LOGIN / SIGN UP SCREEN
****************************************/
.Popup-full-scroll {
  position: fixed;
}

.Popup-login-container {
  background-color: #fff;
  border-radius: 0.8rem;
  margin: 1rem 0;
  padding: 2rem 0;
}

.Popup-login-container > p {
  text-align: center;
  font-size: 1rem;
}

.Popup-login-container
  > .Phone-signin-container
  > form
  > .Signup-input
  > button {
  margin-left: -37px !important;
}
.logo-header {
  width: 10rem;
  padding: 1rem;
}

.Login-options-container {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Signin-heading {
  font-weight: 400;
  font-size: 0.8rem;
  color: var(--color-primary-black);
  margin-bottom: 0.5rem;
}

.Signin-heading > p {
  width: 20rem;
}
.Signin-form-container {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 1rem;
}

.Signup-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Signup-input > button {
  margin-left: -37px;
}

.Signup-input > input {
  width: var(--width-input-large);
  height: 3rem;
  background: none;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  padding: 0.3rem;
  color: var(--color-primary-black-80);
  font-weight: 500;
  font-size: 1.2rem;
  border: solid 1px var(--color-primary-black-20);
}

.Signup-input > input::placeholder {
  color: var(--color-primary-black-60);
}

.Social-signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Social-signin-container > button {
  width: 18rem;
  height: 3rem;
  display: flex;
  align-items: center;
  margin: 1rem 1rem 0.5rem 1rem;
  padding: 0 0.5rem 0 0;
  font-weight: 700;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
}

.google-signin-btn {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.7);
  outline: 1px solid rgba(0, 0, 0, 0.3);
}

.fb-signin-btn {
  background-color: var(--color-facebook-blue);
  color: #fff;
}

.fb-signin-btn > img,
.google-signin-btn > img {
  width: 3rem;
  margin-right: 2rem;
}

/* .google-signin-btn > span, .fb-signin-btn>span {
  width: 100%;
  text-align: center;
} */

.google-signin-btn:hover {
  background-color: #fff;
}

.fb-signin-btn:hover {
  background-color: var(--color-facebook-blue);
}

.Divider-header {
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.3);
}

.Divider-line {
  width: 10rem;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  margin: 0.5rem;
}
/*
CHECKBOX
*/
/* .form-check-element {
  display: flex;
  align-items: center;
  background-color: white;
  border: rgba(0, 0, 0, 0.1) solid 1px;
  padding: 0.4rem 0.8rem;
  border-radius: 2rem;
  margin: 0rem 0.8rem 0 0;
  width: fit-content;
  color: var(--font-color-primary);
}

.check-label {
  width: 2rem;
  height: 2rem;
}

input[type="radio"] {
  -webkit-appearance: initial;
  appearance: initial;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  margin-left: 0.5rem;
  border: none;
  background: var(--grid-giveaway-create-background);
  position: relative;
  transition: all 0.3s;
}

input[type="radio"]:checked {
  background: var(--grid-giveaway-create-background);
  outline: var(--color-input-disabled) solid 2px;
}

input[type="radio"]:checked:after {

  content: "•";
  color: var(--color-primary-dark);
  font-family: "Poppins", sans-serif;
  font-size: 3.7rem;
  font-weight: 600;

  position: absolute;
  left: 50.5%;

  top: 44.5%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: fade-in ease-in 0.3s;

} */

/* .Ad-unit-container {
  min-width: 125px;
  width: fit-content;
  background-color: #f4f4f4;
  padding: 5px;
  color: #bebebe;
} */

/* .Ad-unit-container::before {
  content: "AD";
} */

.Ad-unit-container {
  min-width: 125px;
  width: 100%;
  /*  min-height: 60px; */
  /*   background-color: #f4f4f4; */
  /*  padding: 5px; */
  color: #bebebe;
}

.Accent-section-text {
  background-color: var(--color-accent-20);
  color: var(--color-accent);
  padding: 6px 12px;
  font-size: "14px";
}

.Ad-unit-popup {
  width: 100%;
  position: fixed;
  top: 55px;
  left: 0;
  z-index: 20;
  margin: auto;
  background: none !important;
}

.Ad-unit-popup::before {
  content: "" !important;
}

.Ad-popup-buttons {
  margin: -16px -24px;
  padding: 24px;
  background-color: var(--color-accent-10);
}

.Nav-btn {
  width: 100%;
  height: 70px;
  margin: -2px 0 6px 0;
  text-align: center;
  transition: all 0.4s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.Nav-btn > img {
  width: 20px !important;
  height: 20px !important;
}

.Nav-btn > p {
  font-size: 0.7rem;
  font-weight: 400;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
}

.Nav-btn-active {
  background-color: #000;
  filter: invert(100%);
  /*   box-shadow: 1px 0 4px #e6e6e660; */
}

.User-avatar {
  padding-bottom: calc(22px + 0.5rem);
  text-align: center;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 15%,
      #fff 15%,
      #fff 85%,
      rgba(0, 0, 0, 0) 85%
    )
    0% 0% 100% 0%;
}

.User-avatar > img {
  width: 50px;
  outline: solid 4px #fff;
  border-radius: 100%;
}

.Header-large {
  grid-area: header;
  padding: 0.5rem;
  background-color: #fff;
}

.Header-content {
  width: 50%;
  margin: 1rem auto;
  text-align: center;
}

.Header-content > h3 {
  font-size: var(--font-size-header-large);
  font-weight: 400;
  color: var(--color-primary-dark);
}

.Header-content > h4 {
  color: var(--color-accent);
  text-align: center;
}

.Header-content > p {
  text-align: center;
  color: var(--color-primary-dark);
}

.Header-slim {
  grid-area: header;
}

.Header-medium {
  grid-area: header;
}

.Alias-header > p {
  margin-left: 2rem;
  color: var(--color-primary-dark);
  font-size: var(--font-size-header-medium);
  font-weight: 300;
}

.Header-slim,
.Header-medium {
  display: flex;
  flex-direction: column;
  background-color: var(--color-header-light);
  font-size: var(--font-size-header-medium);
  font-weight: 300;
  text-align: center;
  background-color: var(--color-header-light);
  color: var(--color-primary-dark);
  padding: 1rem 0.5rem;
}

.Giveaway-page {
  grid-area: page_content;
  padding: 1.5rem 1.5rem 0rem 1.5rem;
  background-color: #f1f1f1;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.Giveaway-dash-list-page {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 1rem 1rem 0 0;
  padding: 2rem 4rem;
  overflow-y: scroll;
}

.Giveaway-list-page::-webkit-scrollbar {
  height: 0 !important;
  width: 0 !important;
}

.Giveaway-listing-card:hover {
  box-shadow: 2px 8px 18px 10px #e8e8e8;
}

.Giveaway-listing-card > p {
  margin-top: 1rem;
}

.Giveaway-listing-card > span {
  width: 100%;
  border-radius: 0.8rem;
}

.Giveaway-listing-card-footer {
  margin-top: 1rem;
}

.Header-medium > p,
.Header-slim > p {
  width: 100%;
  font-size: var(--font-size-header-medium);
  padding: 0 2rem 0 1rem;
}

.List-page-container {
  grid-area: form;
  margin: 0.3rem;
  height: calc(100% - 0.3rem);
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary-light-background);
}

.List-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  flex-wrap: wrap;
  background: var(--grid-giveaway-create-background);
  padding: 1rem 0;
}

.Influencer-thumbnail {
  width: 33%;
  flex-shrink: 0;
  height: fit-content;
  text-align: center;
  color: var(--color-primary-dark);
}

.Footer-btn-container {
  height: 15%;
  width: 100%;
  text-align: center;
  padding-top: 0.5rem 0 0.5rem 0;
  background-color: var(--color-primary-light-background);
}

.Handle {
  width: 100%;
  max-height: fit-content;
  background-color: #fff;
  margin: 0.8rem auto;
  padding: 0.5rem;
  border-radius: 1rem;
}

.Handle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Handle-header > img {
  width: 2rem;
}

.Handle-header > span {
  color: rgba(0, 0, 0, 0.8);
  font-size: var(--font-size-small);
}

.Handle-card-container {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
}

.Handle-card {
  width: fit-content;
  flex-shrink: 0;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Handle-card > img {
  width: 4rem;
}

.Handle-card > p {
  background-color: var(--color-primary-dark);
  min-width: 6rem;
  color: #fff;
  text-align: center;
  margin-top: 1rem;
  padding: 0.3rem;
  border-radius: 0rem 0rem 0.3rem 0.3rem;
  font-size: var(--font-size-small);
}

.Popup-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Popup-box {
  background-color: #fff;
  width: var(--popup-box-width);
  border-radius: 1rem;
  /*  padding: 1rem 1rem; */
  padding-bottom: 16px;

  animation: slide-up 300ms ease-in 1;
  height: fit-content;
  max-height: 650px;

  overflow-y: scroll;
  position: relative;
  z-index: 12;
}

.Popup-box-ad-bottom {
  top: 120px;
  max-height: calc(90vh - 350px);
}

.Popup-header-empty {
  width: var(--popup-box-width);
  position: relative;
  text-align: right;
  padding: 1rem 0;
}

.Close-popup-round {
  background-color: #fff;
  width: 35px;
  height: 35px;
  padding: 5px;
  border-radius: 100%;
}

.Close-popup-round > img {
  width: 25px;
  height: 25px;
}

.Popup-box::-webkit-scrollbar,
.Popup-box::-webkit-scrollbar-thumb {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}

.Giveaway-listing-card {
  min-width: 22rem;
  background-color: #fff;
  padding: 0.7rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  transition: box-shadow 0.5s;
}

@keyframes slide-up {
  from {
    transform: translate(0%, 100%);
  }
  to {
    transform: translate(0%, 0%);
  }
}

.Popup-close-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 11;
}

.Popup-header {
  border-bottom: solid 1px var(--color-primary-black-10);
  width: 100%;
  padding: 16px 24px;
}

.Popup-header > p {
  display: inline;
  font-weight: 600;
  color: var(--color-primary-black);
  font-size: 18px;
}

.Popup-header > button {
  display: inline;
  float: right;
}

.Popup-header > button:hover {
  background: none;
}

.Popup-content {
  padding: 16px 24px 0 24px;
  font-size: 0.9rem;
  word-break: break-word;
}

.Popup-content > button {
  margin-top: 1rem;
}

.Popup-input {
  width: 100%;
  border: none;
  background-color: #f8f8f8;
  padding: 1rem 0.8rem;
  margin: 1rem 0;
  border-radius: 0.6rem;
  color: black;
  font-size: 1rem;
}

.Popup-input::placeholder {
  color: #000;
}

.Connect-code-input {
  width: 100%;
  border: none;
  background-color: #f4f4f4;
  padding: 0.75rem 0.8rem;
  margin: 1rem 0;
  border-radius: 0.6rem;
  color: var(--color-primary-black);
  font-size: 1rem;
  border: solid 2px transparent;
  transition: border 0.2s linear, background-color 0.2s linear;
}

.Connect-code-input:focus {
  border: solid 2px var(--color-accent);
  background-color: var(--color-accent-10);
  outline: none;
}

.Connect-code-input::placeholder {
  color: var(--color-primary-black-60);
}

.Upload-progress {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: var(--color-accent);
  border-radius: 0.5rem;
  transition: all 0.5s;
}

.Upload-progress-text {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0.5rem;
  bottom: 0.5rem;
}

.Popup-uploaded-preview-mini {
  margin: auto;
  width: 50%;
  height: 10rem;
}

.Popup-uploaded-preview {
  width: 100%;
  height: 10rem;
}

.Popup-uploaded-preview > img {
  object-fit: cover;
  border-radius: 1rem;
}

.Choose-file-option {
  text-align: center;
}

.Choose-file-option > input {
  display: none;
}

.Choose-file-button {
  color: var(--color-primary-dark);
  text-decoration: underline;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
}

.Upload-option > input {
  width: 100%;
  padding: 0.8rem 0.5rem;
  border-radius: 0.7rem;
  border: solid rgba(0, 0, 0, 0.2) 0.13rem;
}

.Confirmation-code-container {
  color: var(--color-accent);
  background-color: #f4f4f4;
  display: flex;
  font-size: 1.3rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  margin: 1.5rem 0;
}

.Popup-note {
  font-size: 0.8rem;
}

.share-button {
  background-color: #fff;
  padding: 0.25rem 0.25rem;
}

.share-button:hover {
  background-color: #fff;
}

.share-button > img {
  height: 2rem;
}

.Winner-reward-list-container {
  gap: 20px;
}

.Winner-reward-list-row {
  background-color: #fff;
  flex-basis: 200px;
  max-width: 200px;
  padding: 1rem;
  border-radius: 0.7rem;
  transition: box-shadow 0.3s linear, bottom 0.3s ease-in;
}

.Winner-reward-list-row:hover {
  box-shadow: 0px 0px 20px 6px var(--color-primary-black-05);
  position: relative;
  bottom: 6px;
}

.Winner-reward-image {
  width: 100%;

  border-radius: 0.5rem;
}

.Winner-reward-image > img {
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
  border: 1px solid var(--color-primary-black-10);
}

.Winner-reward-image-title {
  position: relative;
}

.Winner-reward-image-title > p {
  position: absolute;
  top: -2rem;
  font-size: 0.75rem;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 0.2rem 0;
  border-radius: 0 0 0.5rem 0.5rem;
}

.Reward-coupon-code-section {
  background-color: rgb(125 62 225 / 5%);
  margin: 0 -24px;
  padding: 1rem 24px;
}

.Winner-reward-content > p {
  color: var(--color-primary-black);
  font-size: 16px;
  font-weight: 500;
  /* margin-bottom: 0.5rem; */
}

.Winner-reward-content > button {
  margin-top: 1rem;
}

.Connect-status-text {
  min-width: 100vw;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}

.Connect-status-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: -10rem;
  min-width: 100vw;
  min-height: 100vh;
}

.Connect-success {
  background-image: url("../img/registration/connect_success_backdrop.png");
}

.Connect-failed {
  background-image: url("../img/registration/connect_failed_backdrop.png");
}

.Popup-menu-list {
  box-shadow: 2px 4px 12px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  cursor: pointer;
  animation: Opacity0to100 0.2s linear;
}

.Popup-menu-list > li {
  color: var(--color-primary-dark);
  font-weight: 500;
  padding: 0.5rem 0.8rem;
}

.Popup-menu-list > li:hover {
  background-color: var(--color-primary-light);
}

.Form-field-large {
  width: 300px;
}

.Form-field-half {
  width: 45%;
  display: inline-block;
  margin-right: 10px;
}

@keyframes Opacity0to100 {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes ProgressCounter {
  0% {
    transform: translateY(100%);
    opacity: 0%;
  }
  50% {
    transform: translateY(0%);
    opacity: 100%;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0%;
  }
}

.Bottom-sheet-container {
  width: 100vw;
  height: var(--full-viewport-height);
  position: fixed;
  z-index: 13;
  display: grid;
  top: 0;
  left: 0;
}

.Bottom-sheet-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.Bottom-sheet {
  width: 375px;
  max-height: 600px;

  position: fixed;
  z-index: 11;
  align-self: center;
  justify-self: center;
  background: #fff;

  overflow-y: scroll;
  padding-bottom: 32px;
  border-radius: 14px;
}

.Bottom-sheet::-webkit-scrollbar,
.Bottom-sheet::-webkit-scrollbar-thumb {
  width: 0 !important;
  height: 0 !important;
}

.Bottom-sheet-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;

  font-size: var(--font-size-H2);
  font-weight: var(--font-weight-semibold);
  color: var(--color-primary-black);
}

.Bottom-sheet > * {
  padding-left: 24px;
  padding-right: 24px;
}

.Tip-header {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 4px;

  font-size: var(--font-size-SH1);
  font-weight: var(--font-weight-semibold);
}

.Tip-content {
  padding: 16px 12px;
  border-radius: 12px;
  font-size: var(--font-size-Text1);
  font-weight: var(--font-weight-regular);
  color: var(--color-primary-black-80);
}

.Purple-section {
  background-color: var(--color-accent-10);
  padding: 24px 24px;
  font-size: 14px;
  border-radius: 0.8rem;
  /*   font-weight: 700; */
}

@media only screen and (max-width: 600px) {
  .Popup-box {
    max-height: 70vh;
  }

  .Popup-box-ad-bottom {
    position: fixed;
    bottom: 16px;
    width: calc(100vw - 32px);
    max-height: calc(90vh - 150px);
    top: unset;
  }

  .Header-content {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .Header-large {
    padding: 1rem 0;
  }

  .Giveaway-dash-list-page {
    padding: 1rem;
    border-radius: 0;
  }

  .Connect-status-container {
    background-color: unset;
  }

  .Winner-reward-list-container {
    justify-content: center;
    gap: 10px;
  }

  .Winner-reward-list-row {
    flex-basis: 45%;
  }

  /*   .Winner-reward-list-row {
    min-width: 90vw;
    max-width: 90vw;
  } */

  .Bottom-sheet {
    width: calc(100vw - 32px);
    max-height: 85vh;
    left: 16px;
    bottom: 16px;
  }
}

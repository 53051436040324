.Dashboard-layout {
  display: grid;
  height: 100%;
  grid-template-areas: var(--grid-dashboard-layout-areas);
  grid-template-rows: var(--grid-dashboard-layout-rows);
  grid-template-columns: var(--grid-dashboard-layout-columns);
  /*   overflow-y: scroll; */
  overflow-x: hidden;
}

.Dashboard-container {
  grid-area: body_content;
  width: calc(100vw - 70px);
  /*   background-color: #d4e3e3; */
  background-color: #eceef8;
  padding: 1.5rem 1.5rem 0 1.5rem;
  overflow-y: scroll;
  /*  display: grid;
  grid-template-areas: var(--grid-dashboard-areas);
  grid-template-rows: var(--grid-dashboard-rows);
  grid-template-columns: var(--grid-dashboard-columns); */
}

.Sidebar-header-light {
  grid-area: sidebar_header;
  background-color: #fff;
  border-image: linear-gradient(
      to right,
      #fff 15%,
      #3b8e9e7c 15%,
      #3b8e9e7c 85%,
      #fff 85%
    )
    0% 0% 100% 0%;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.Sidebar-header-light > h3 {
  font-size: var(--font-size-heading);
  font-weight: 600;
  text-align: center;
  color: var(--font-color-black);
}

.Sidebar-menu-light {
  grid-area: sidebar_menu;
  background-color: #fff;
}

.Sidebar-header {
  grid-area: sidebar_header;
  background-color: #e6e6e6;
}

.Sidebar-menu {
  grid-area: sidebar_menu;
  background-color: #e6e6e6;
  color: var(--font-color-black);
}

.Participant-sidenav {
  padding-top: 100px;
  padding-bottom: 100px;
  grid-area: sidenav;
  display: var(--visibility-desktop) !important;
  background: linear-gradient(to bottom, #06beb6, #48b1bf);
}

.influencer-sidenav {
  padding-top: 100px;
  grid-area: sidenav;
  display: var(--visibility-desktop) !important;
  background: linear-gradient(
    180deg,
    #c33367 0%,
    #c26161 60%,
    #a13c5b 80%,
    #c24077 100%
  );
}

.Manager-profile-card {
  grid-area: sidebar_header;
  height: 135px;
  padding: 1rem 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: #f0f0f0 solid 2px;
  border-right: solid 2px #f0f0f0;
}

.Side-menu {
  grid-area: sidebar_menu;
  border-right: solid 2px #f0f0f0;
}

.Sidebar-list-switch-container {
  padding: 1rem;
  width: 100%;
  display: flex;
}

.Sidebar-list-switch-container > span {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
  min-width: 50%;
  cursor: pointer;
  transition: color 0.2s;
}

.Sidebar-list-switch-container > span:hover {
  color: var(--font-color-primary) !important;
  font-weight: 600;
}

.Sidebar-list-switch-active {
  font-weight: 600 !important;
  color: var(--font-color-primary) !important;
  border-bottom: 2px solid var(--font-color-primary50);
}

.Sidebar-list-switch-active::before {
  content: "• ";
}
.Empty-side-menu-button-card {
  width: 324px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.Empty-side-menu-button-card > p {
  width: 70%;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 600;
  color: var(--font-color-primary50);
}

.Empty-side-menu-button-card > button {
  width: fit-content;
  padding: 0.5rem 2rem;
}

.Inf-profile-container {
  display: var(--display-container);
  overflow-x: none;
  max-height: 90%;
  overflow-y: scroll;
  padding: 1rem 0.5rem;
}

.Inf-profile-card {
  min-width: 18rem;
  max-width: 18rem;
  height: 135px;
  font-size: 0.9rem;
  background-color: #fff;
  border-radius: 1rem;
  margin: 1rem 0.5rem;
  padding: 0.5rem;
  display: grid;
  grid-template-rows: 1fr 2fr 2fr;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 0px 10px;
  grid-template-areas:
    "avatar name name msg"
    "avatar stat stat stat"
    "platform platform platform platform";
  transition: background-color 0.3s;
  border: #ededed solid 2px;
}

.Inf-avatar {
  height: 65px;
  width: 65px;
  grid-area: avatar;
  /*box-shadow: 0px 4px 8px #70707091;*/
  border-radius: 100%;
  margin-right: 0.5rem;
}

.Inf-name {
  grid-area: name;
  padding-top: 0.3rem;
}

.Inf-stat {
  grid-area: stat;
}

.Inf-state > p {
  width: max-content;
  margin-top: 0.3rem;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 0.85rem;
}

.Inf-state-disconnected > p {
  background-color: #ffe7e7;
  color: #ff6363;
}

.Inf-state-connected > p {
  background-color: #d2f4de;
  color: #099472;
}

.Inf-chat {
  grid-area: msg;
  width: 40px;
  height: 40px;
}

.Platform-overview-button {
  grid-area: platform;
  height: calc(100% + 0.6rem);
  width: calc(100% + 1.1rem);
  margin-left: -0.5rem;
  border-radius: 0 0 1rem 1rem;
  text-align: center;
}

.Platform-overview-button > button {
  height: 100%;
  width: 100%;
}

.btn-state-connected {
  background-color: #d2f4de;
  color: #099472;
}

.btn-state-connected > button {
  color: #099472;
}

.btn-state-connected > button:hover {
  background: none !important;
}

.btn-state-disconnected {
  background-color: #000;
  color: #fff;
}

.btn-state-disconnected > button {
  color: #fff;
}

.btn-state-disconnected > button:hover {
  background: none !important;
}

.Platform-overview-container {
  grid-area: platform;
  display: flex;
  justify-content: space-around;
}

.Platform-icon {
  height: 25px;
}

.Platform-count-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  width: 5rem;
  padding: 0.2rem 0.3rem;
  margin: 0 0.3rem;
  border-radius: 3rem;
  height: 2rem;
}

.Platform-count-text {
  background-color: #fff;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  text-align: center;
  font-size: 0.7rem;
}

/*********************
EMPTY DASHBOARD CONTENT
**********************/
.Background-popup {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.New-user-container {
  width: var(--giveaway-create-container-width);
  height: 85%;
  overflow-y: scroll;
  display: grid;
  grid-area: page_content;
  grid-template-areas: var(--grid-empty-dashboard-areas);
  grid-template-columns: var(--grid-empty-dashboard-columns);
  grid-template-rows: var(--grid-empty-dashboard-rows);
  padding: 0 1rem;
  color: rgba(0, 0, 0, 0.8);
  background-color: #fff;
  position: absolute;
  margin: auto;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  border-radius: 2rem;
  z-index: 1;
}

.Empty-dashboard-content {
  grid-area: page_content;
  background-color: #f8f8f8;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 1rem;
}

.Placeholder-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  height: 45%;
  width: 100%;
  flex-wrap: wrap;
  overflow-y: hidden;
}

.Placeholder-container > h2 {
  display: flex;
  align-self: flex-end;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  margin: 2rem 1rem;
}

.Placeholder-card {
  display: grid;
  background-color: #f0f0f0;
  flex-shrink: 0;
  width: 30%;
  height: 14rem;
  margin: 2rem 1rem;
  border-radius: 1rem;
}

.Placeholder-card > div {
  margin: auto;
  text-align: center;
}

/* Giveaway listing & participate cards dashboard*/
.Gl-container {
  scroll-behavior: smooth;
  padding: 1rem 0.5rem;
}
/* 
.Gl-container::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: var(--color-primary-light-background);
} */

.Empty-reward-screen {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 14px;
}

.Empty-reward-screen-content {
  width: 100%;
  max-width: 500px;
  height: 90%;
  max-height: 550px;
  margin: auto;
  padding: 14px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60% 1fr 2fr;
  border-radius: 20px;
}

.Empty-reward-screen-content > * {
  align-self: center;
  justify-self: center;
}

.Gl-container::-webkit-scrollbar-thumb {
  /* width: 0;
  height: 0; */
  background-color: #d8e8ec;
}

.Glc {
  min-width: 400px;
  max-width: 400px;
  min-height: 360px;
  /*   margin-right: 20px; */
  border-radius: 1rem;
  border: solid 1.5px #ecf4f6;
  background-color: #fff;
}

.Glc-alias-header {
  padding: 0.8rem 0.5rem;
  border-bottom: 1px solid #ecf4f6;
}

.Glc-alias {
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--color-primary-black);
  font-weight: 500;
}

.Glc-alias > img {
  width: 45px;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: solid 2px #fff;
}

.Glc-banner {
  width: 100%;
  aspect-ratio: 2/1;
  background-color: #fff;
}

.Glc-duration-label {
  background-color: #e6f7ed;
  color: #179449;
  border-radius: 0.3rem;
  margin: 1rem 0.5rem;
  padding: 0.5rem 0.5rem;
  font-size: 0.95rem;
  font-weight: 500;
  width: fit-content;
}

.Glc-content > p {
  height: 3rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  padding: 1rem 0.5rem;
}

.Glc-action-item {
  background-color: var(--color-primary-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0.5rem;
  border-radius: 0.3rem 0.3rem 0.8rem 0.8rem;
}

.Glc-reward-tag {
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 0.5rem;
  height: min-content;
  width: 3rem;
  padding: 0.4rem;
}

.Glc-reward-tag > img {
  width: 1.5rem;
}

.Navigator-arrows-container {
  position: relative;
}

.Gl-next-arrow,
.Gl-prev-arrow {
  background-color: #fff;
  position: absolute;
  margin-top: 190px;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  text-align: center;
  box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.Gl-next-arrow > img,
.Gl-prev-arrow > img {
  width: 1.2rem;
}

.Gl-prev-arrow {
  left: -10px;
}

.Gl-next-arrow {
  left: calc(100vw - 300px);
}

#GlContainer {
  gap: 20px;
}

#GpContainer {
  gap: 20px;
}

.Gpc {
  min-width: 360px;
  max-width: 360px;
  border-radius: 1rem;
  border: 1px solid #d8e8ec;
  /*   margin-right: 2rem; */
}

.Gpc-banner-bkg-header {
  min-height: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*   border-radius: 1rem 1rem 0 0; */
  background-repeat: no-repeat;
  background-color: #f4f4f4;
}

.Gpc-header-alias {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /*   background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); */
  padding: 0.5rem;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.Gpc-header-options {
  padding: 0 !important;
  margin: 0 !important;
  min-height: 40px;
  min-width: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
}

.Gpc-header-options > img {
  padding: 0 !important;
  margin: 0 !important;
  height: 25px;
  width: 25px;
}

.Gpc-alias {
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}

.Gpc-alias > img {
  width: 45px;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
  border: solid 2px #fff;
}

.Gpc-content {
  width: 100%;
  height: 150px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Gpc-content > p {
  margin: 0rem 0;
}

.Gpc-pi-cash-earned {
  color: #e19027;
  font-weight: 600;
}

.Gpc-pi-cash-earned > div > img {
  height: 1.5rem !important;
  width: 1.5rem !important;
  margin-right: 0.3rem;
}

.Gpc-status-footer {
  background: var(--color-primary-black-80);
  color: #fff;
  font-weight: 300;
  padding: 0.2rem 0.5rem;
  font-size: 0.9rem;
}

.Gpc-status-footer > p {
  font-weight: 500;
}

.Gpc-status-footer > p > span {
  color: #00ff57;
}

.Gpc-status-footer > p > span::before {
  content: "⬤ ";
  font-size: 0.75rem;
}

.Gpc-tasks-footer {
  padding: 0.5rem;
  border-radius: 0 0 0.8rem 0.8rem;
}

.Gpc-tasks-footer-background-green {
  background-color: #e6f8ed;
  border-top: solid 2px #179449;
}

.Gpc-tasks-footer-background-green > p > span {
  color: #179449;
}

.Gpc-tasks-footer-background-yellow {
  background-color: #fdf2e6;
  border-top: solid 2px #e68717;
}

.Gpc-tasks-footer-background-yellow > p > span {
  color: #e68717;
}

.Gpc-tasks-footer-background-red {
  background-color: #fde6e6;
  border-top: solid 2px #e61717;
}

.Gpc-tasks-footer-background-red > p > span {
  color: #e61717;
}

@media only screen and (max-width: 800px) {
  /*   .Gl-container::-webkit-scrollbar-thumb, */
  .Gl-container::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    display: none;
  }

  .Gpc {
    min-width: 300px;
    max-width: 300px;
  }

  .Gpc-banner-bkg-header {
    min-height: 150px;
  }

  .Placeholder-card {
    width: 28rem;
  }
  .Empty-side-menu-button-card {
    width: 100%;
    height: 12rem;
  }
  .Inf-profile-container {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .Dashboard-container {
    width: 100vw;
    padding: 0;
  }
  .Gl-prev-arrow,
  .Gl-next-arrow {
    display: none;
  }

  .Glc {
    min-width: 300px;
    max-width: 300px;
  }

  /*   .Glc-banner {
    min-height: 150px;
    background-color: #f4f4f4;
  } */
  .Empty-reward-screen {
    background: linear-gradient(to bottom, #fff, #3b8d9e);
  }
  .Empty-reward-screen-content > section > p {
    width: 250px;
    margin: auto;
  }
}
